<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            this.is_modify
              ? $t("account.Edit Account")
              : $t("account.Create Account")
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        用户信息列表项
      </v-alert>
      <v-row align="center">
        <v-col cols="3">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("account.label.parent_directory") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <span style="font-size:14px;color:rgba(0,0,0,.6)">
            企业根目录
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text" v-if="login_name">*</span>
            {{ $t("account.label.login_name") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-if="login_name"
            rows="1"
            v-model="account['login_name']"
            :placeholder="$t('account.placeholder.login_name')"
            :hint="$t('account.hint.login_name')"
            persistent-hint
            :readonly="is_modify"
            :disabled="is_modify"
          ></v-text-field>
          <v-text-field
            v-if="!login_name"
            rows="1"
            v-model="account['login_name']"
            :placeholder="$t('account.placeholder.login_name')"
            :hint="$t('account.hint.login_name')"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("account.label.fullname") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="account.fullname"
            :placeholder="$t('account.placeholder.fullname')"
            :hint="$t('account.hint.fullname')"
            persistent-hint
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense v-if="0">
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("account.label.password") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="account['password']"
            :placeholder="$t('account.placeholder.password')"
            :hint="$t('account.hint.password')"
            persistent-hint
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("account.label.email") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="account['emailaddress']"
            :placeholder="$t('account.placeholder.email')"
            :hint="$t('account.hint.email')"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("account.label.mobile") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="account['mobilephone']"
            :placeholder="$t('account.placeholder.mobile')"
            :hint="$t('account.hint.mobile')"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense v-if="0">
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("account.label.external_id") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="account['external_id']"
            :placeholder="$t('account.placeholder.external_id')"
            :hint="$t('account.hint.external_id')"
            persistent-hint
            :readonly="is_modify"
            :disabled="is_modify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span>是否禁用</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-checkbox
            class="v-label"
            v-model="account['disabled']"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("account.label.expiry_time") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-menu
            class="datepicker-menu"
            ref="menu"
            v-model="show_datepicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="account['expiry_time']"
                :placeholder="$t('account.placeholder.expiry_time')"
                :hint="$t('account.hint.expiry_time')"
                append-icon="mdi-calendar-clock"
                readonly
                rows="1"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="account['expiry_time']"
              no-title
              :min="today"
              scrollable
              :show-current="false"
              @input="show_datepicker = false"
              :day-format="date => date.split('-')[2]"
              locale="zh-cn"
            >
              <v-btn text color="primary" @click.stop="setExpiryDate(90)">
                三个月
              </v-btn>
              <v-btn text color="primary" @click.stop="setExpiryDate(180)">
                六个月
              </v-btn>
              <v-btn text color="primary" @click.stop="setExpiryDate(365)">
                一年
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="is_modify ? modify() : create()"
            >{{ is_modify ? "提交修改" : "创建" }}</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { iso8601 } from "@/util/misc";
import bus from "@/util/eventBus";

export default {
  name: "AccountEditor",
  props: ["entity_info", "is_modify", "show"],
  data() {
    let isTrueUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    return {
      account: {},
      defaults: {},
      login_name: null,
      valid: true,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      },
      // UI inter-communication
      show_datepicker: false
    };
  },
  created() {
    if (this.entity_info) {
      this.fetchData();
    }
  },

  methods: {
    resetForm(clear) {
      if (clear) {
        this.defaults = {};
      }
      this.account = Object.assign({}, this.defaults);
    },
    modify() {
      if (this.$refs.form.validate()) {
        let payload = {};
        if (this.login_name) {
          payload = {
            fullname: this.account["fullname"],
            emailaddress: this.account["emailaddress"],
            mobilephone: this.account["mobilephone"],
            expiry_time: this.account["expiry_time"],
            disabled: this.account["disabled"]
          };
        } else {
          payload = {
            login_name: this.account["login_name"],
            fullname: this.account["fullname"],
            emailaddress: this.account["emailaddress"],
            mobilephone: this.account["mobilephone"],
            expiry_time: this.account["expiry_time"],
            disabled: this.account["disabled"]
          };
        }

        this.$http
          .post(`api/account/${this.$route.params.id}/modify`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("refresh");

            return "账户已成功修改";
          })
          .catch(({ code, message }) => {
            throw `账户修改失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        this.account["department_id"] = 1;
        this.$http
          .post(`api/account/create`, this.account)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("refresh");
            return "创建成功";
          })
          .catch(({ code, message }) => {
            throw `账号创建失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    fetchData() {
      if (this.entity_info.expiry_time) {
        this.account.expiry_time = this.entity_info.expiry_time.split("T")[0];
      }

      if (!this.entity_info["login_name"]) {
        this.login_name = null;
      } else {
        this.login_name = true;
      }
      this.defaults = Object.assign(this.account, this.entity_info.attributes);
      this.defaults["login_name"] = this.entity_info.login_name;
      this.defaults["disabled"] = this.entity_info.disabled;
      this.defaults["external_id"] = this.entity_info.external_id;
      this.defaults["department_name"] = this.entity_info.departments[0].name;
    },
    setExpiryDate(offsetDays) {
      this.show_datepicker = false;
      let now = new Date();
      now.setDate(now.getDate() + offsetDays);
      this.account["expiry_time"] = iso8601(now);
    }
  },
  watch: {
    entity_info() {
      this.fetchData();
    }
  },
  computed: {
    today() {
      return iso8601(new Date());
    }
  }
};
</script>

<style lang="sass">
.v-card__actions
  justify-content: flex-end
  .v-btn
    font-weight: bold
</style>
